<template>
  <div class="main-wrapper">
    <title-bar
      title="Contact Us"
      subtitle=""
    />
    <div class="content">
      <div class="container">
        <div class="contact-wrapper">
          <div class=" pt-0">
            <div class="row">
              <div class="col-lg-7 mb-4">
                <h2>Contact Us</h2>
                <p class="lead">
                  Get in touch and let us know how we can help. Fill out the form and we’ll be in touch as
                  soon as possible.
                </p>
              </div>
            </div>
            <div class="contact-form-wrapper">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <div class="input-group input-group-alternative">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ni ni-user-run" /></span>
                      </div>
                      <input
                        class="form-control"
                        placeholder="First Name"
                        type="text"
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <div class="input-group input-group-alternative">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ni ni-user-run" /></span>
                      </div>
                      <input
                        class="form-control"
                        placeholder="Last Name"
                        type="text"
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <div class="input-group input-group-alternative">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ni ni-email-83" /></span>
                      </div>
                      <input
                        class="form-control"
                        placeholder="Email address"
                        type="email"
                      >
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <div class="input-group input-group-alternative">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ni ni-user-run" /></span>
                      </div>
                      <input
                        class="form-control"
                        placeholder="Subject"
                        type="text"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mb-4">
                <textarea
                  class="form-control form-control-alternative"
                  name="name"
                  rows="4"
                  cols="80"
                  placeholder="Type a message..."
                />
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-primary btn-round btn-block btn-lg"
                >
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleBar from 'Components/globalFrontendComponents/TitleBar';
export default {
	components: {
		TitleBar: TitleBar
	}
};
</script>
